import React, { Component } from "react";
import AuthService from "../services/auth.service";
import DataService from "../services/data.service";
import MyUnitsForm from "./myUnitsForm.component";
import MapboxGLUnitsMap from "./mapboxGLUnitsMap";


export default class BoardUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: [],
            mapview: [],
            config: []
        };
    }

    componentDidMount() {
        this.getData();

        setInterval(this.getData, 30000);  // Update every 30th sec
    }

    getData = () => {
        let currentUser = AuthService.getCurrentUser()
        if (currentUser !== null) {
            DataService.getUnits(currentUser.id).then(res => {
                let contentData = []
                let mapData = []
                let cfgData = []
                for (let i = 0; i < res.data.length; i++) {
                    let cd = {}
                    let md = {}
                    let cfg = {}
                    cd["id"] = md["id"] = cfg["id"] = res.data[i].id
                    cd["Navn"] = md["Navn"] = cfg["Navn"] = (res.data[i].nick !== null ? res.data[i].nick : '')
                    cfg["IMEI"] = res.data[i].IMEI
                    let myDateTime = new Date(res.data[i].time)
                    cd["TimeStamp"] = myDateTime.toLocaleDateString('no-NO') + " " + myDateTime.toLocaleTimeString('no-NO')
                    if (res.data[i].status !== null) {
                        if (res.data[i].status.charAt(0) === 'A') {
                            cd["Status"] = 'auto'
                        } else if (res.data[i].status.charAt(0) === 'M') {
                            cd["Status"] = 'manuell'
                        } else {
                            cd["Status"] = 'av'
                        }
                        md["Status"] = res.data[i].status
                    } else {
                        cd["Status"] = '-'
                        md["Status"] = '-'
                    }
                    if (res.data[i].data !== null) {
                        if (res.data[i].data.BruksvanntanknivaaProsent !== null) {
                            cd["Bruksvann"] = md["Bruksvann"] = res.data[i].data.BruksvanntanknivaaProsent;
                        }
                        if (res.data[i].data.SeptikktanknivaaProsent !== null) {
                            cd["Septikk"] = md["Septikk"] = res.data[i].data.SeptikktanknivaaProsent;
                        }

                    }
                    md["geometry"] = res.data[i].geometry
                    if( res.data[i].config !== null ) {
                        Object.entries(res.data[i].config).forEach(([key,value]) => { cfg[key] = value })
                    }
                    contentData.push(JSON.stringify(cd))
                    if( res.data[i].geometry !== null ) {
                        mapData.push(JSON.stringify(md))
                    }
                    cfgData.push(JSON.stringify(cfg))
                }
                this.setState({
                    content: contentData,
                    mapview: mapData,
                    config: cfgData
                });
            },
                error => {
                    this.setState({
                        content: [],
                        mapview: [],
                        config: []
                    });
                }
            );
        } else {
            this.setState({
                content: [],
                mapview: [],
                config: []
            });
        }
    }

    render() {
        const myContent = this.state.content
        const myMapView = this.state.mapview
        const myConfig = this.state.config        

        return (
            <>
            <div className="grid_container">
                {myContent.length === 0 && (
                    <span className="viewcard21">
                        <h3>No Access, please login again.</h3>
                    </span>
                )}
                {myMapView && myMapView.length > 0 && (
                    <span className="viewcard21">
                        <MapboxGLUnitsMap myContent={myMapView} />
                    </span>
                )}
                {myContent && myContent.length > 0 && (
                    <MyUnitsForm myContent={myContent} myConfig={myConfig} />
                )}
            </div>
            </>
        );
    }
}
