import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import MyUnitsDialogForm from './myUnitsDialogForm.component'


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});



function createData(id, Name, Bruksvann, Septikk, TimeStamp, Status) {
    return { id, Name, Bruksvann, Septikk, TimeStamp, Status };
}


export default function MyUnitsForm({ myContent, myConfig }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [loading, setloading] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(-1);
    const rows = []

    const handleClose = () => {
        setloading(true);
        setTimeout(function () {
            setOpen(false);
            setloading(false);
        }, 3000);
    };

    function handleClick(rowId) {
        console.log('Clicked on row: ' + rowId);
        setSelectedRow(rowId);
        setOpen(true);
    }


    for(let i = 0; i < myContent.length; i++) {
        let row = {}
        let rd = JSON.parse(myContent[i])
        row = createData(rd.id, rd.Navn, rd.Bruksvann, rd.Septikk, rd.TimeStamp, rd.Status)
        rows.push(row)
    }

    return (
        <>
            <span className="viewcard22">
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Navn</TableCell>
                                <TableCell align="right">Bruksvann</TableCell>
                                <TableCell align="right">Septikk</TableCell>
                                <TableCell align="right">Tidstempling</TableCell>
                                <TableCell align="right">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    onClick={() => handleClick(row.id)}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.Name}
                                    </TableCell>
                                    <TableCell align="right">{row.Bruksvann}</TableCell>
                                    <TableCell align="right">{row.Septikk}</TableCell>
                                    <TableCell align="right">{row.TimeStamp}</TableCell>
                                    <TableCell align="right">{row.Status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </span>
            <Dialog open={open} onClose={handleClose} fullWidth={true} aria-labelledby="form-dialog-title">
                {/* <MyUnitsDialogForm myConfig={myConfig[selectedRow-1]} /> */}
                <MyUnitsDialogForm myUnitId={selectedRow} />
                <DialogActions>
                    <Button onClick={handleClose} color="primary">X</Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
