import React, { Component } from 'react';
import AuthService from "../services/auth.service";
import DataService from "../services/data.service";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';


export default class MyUnitsDialogForm extends Component {
    constructor(props) {
        super(props);

        // "hostname":"rad006","O2Runtime":1,"O3Runtime":1,"O3Disabled":false,"Systemtype":"Graavann001","O2Intervall":1,"Sumppumpetid":8,
        // "MaksverdiBruksvannAN":3105,"BrukstankHNivaaIProsent":85,"BrukstankLLNivaaIProsent":15,"MinimumsnivaaBruksvannProsent":0
        //
        // "P1Fuse":1,"P2Fuse":1,"O3bobler":0,"Sumppumpe":0,"Luftbobler":0,"MB01P1ARaw":3010,"MB01P1Temp":20,"MB01P2ARaw":3012,
        // "MB01P2Temp":20,"TrykkpumpeEn":1,"MB01DigitalIn":1023,"BruksvanntanknivaaProsent":41
        this.state = {
            myUnitId: props.myUnitId,
            IMEI: "",
            myName: "",
            Status: "",
            ManuellKjoringState: false,
            Sumppumpe: false,
            TrykkpumpeEn: false,
            Bruksvann: 0,
            Septikk: 0,
            CfgDump: "",
            DataDump: "",
            loading: false
        };
    }

    componentDidMount() {
        this.getData();

        setInterval(this.getData, 10000);  // Update every 30th sec
    }


    getData = () => {
        let currentUser = AuthService.getCurrentUser()
        if (currentUser !== null) {
            DataService.getUnits(currentUser.id).then(res => {

                let cfgData = res.data.find(({ id }) => id === this.state.myUnitId);
                let myName = ""
                let IMEI = ""
                let myStatus = "--"
                let myManuellKjoringState = false
                let mySumppumpe = false 
                let myTrykkpumpeEn = false
                let myBruksvann = 0
                let mySeptikk = 0
                let myCfgDump = ""
                let myDataDump = ""

                if (cfgData !== undefined) {

                    myName = (cfgData.nick !== null ? cfgData.nick : '')
                    IMEI = cfgData.IMEI
                    myStatus = (cfgData.status !== null ? cfgData.status : '')
                    if(myStatus[0]==='M') {
                        myManuellKjoringState = true;
                    }

                    // Read Config data
                    if (cfgData.config !== undefined) {
                        myCfgDump = JSON.stringify(cfgData.config);
                    }

                    // Read level Data
                    if (cfgData.data !== undefined) {
                        if(cfgData.data.Sumppumpe !== undefined) {
                            mySumppumpe = cfgData.data.Sumppumpe
                        }
                        if(cfgData.data.TrykkpumpeEn !== undefined) {
                            myTrykkpumpeEn = cfgData.data.TrykkpumpeEn
                        }
                        if(cfgData.data.BruksvanntanknivaaProsent !== undefined) {
                            myBruksvann = cfgData.data.BruksvanntanknivaaProsent
                        }
                        if(cfgData.data.SeptikktanknivaaProsent !== undefined) {
                            mySeptikk = cfgData.data.SeptikktanknivaaProsent
                        }
                        myDataDump = JSON.stringify(cfgData.data);
                    }

                }

                this.setState({
                    myName: myName,
                    IMEI: IMEI,
                    Status: myStatus,
                    ManuellKjoringState: myManuellKjoringState,
                    Sumppumpe: mySumppumpe,
                    TrykkpumpeEn: myTrykkpumpeEn,
                    Bruksvann: myBruksvann,
                    Septikk: mySeptikk,
                    CfgDump: myCfgDump,
                    DataDump: myDataDump
                });
            },
                error => {
                    this.setState({
                        myName: "",
                    });
                }
            );
        } else {
            this.setState({
                myName: "",
            });
        }

    }


    render() {
        const myName = this.state.myName

        const handleChange = (event) => {
            this.setState({ [event.target.name]: event.target.checked });

            let IMEI = this.state.IMEI
            let pubMsg = {}
            pubMsg[event.target.name] = event.target.checked

            DataService.MQTTPub(IMEI, JSON.stringify(pubMsg))
            
        };
        
    
        return (
            <>
                <DialogTitle id="form-dialog-title">Konfigurere {myName}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div style={{ height: 500, width: '100%' }}>
                            <FormGroup>
                                <FormHelperText>Status: {this.state.Status}</FormHelperText>
                                <FormControlLabel control={<Switch checked={this.state.ManuellKjoringState} onChange={handleChange} name="ManuellKjoringState" />} label="ManuellKjoringState" />
                                <FormControlLabel control={<Switch checked={this.state.Sumppumpe} onChange={handleChange} name="Sumppumpe" />} label="Sumppumpe" />
                                <FormControlLabel control={<Switch checked={this.state.TrykkpumpeEn} onChange={handleChange} name="TrykkpumpeEn" />} label="TrykkpumpeEn" />
                                <FormHelperText>Bruksvann: {this.state.Bruksvann}%</FormHelperText>
                                <FormHelperText>Septikk: {this.state.Septikk}%</FormHelperText>
                                <FormHelperText>Konfigurasjonsdata: {this.state.CfgDump}</FormHelperText>
                                <FormHelperText>Prosessdata: {this.state.DataDump}</FormHelperText>
                            </FormGroup>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </>
        );
    }
}
