import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import theme from './components/ui/Theme';
import Header from './components/ui/Header';

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./components/componentGrid.css";
import background from './assets/BIO3000Background.jpg';



class App extends Component {
    constructor(props) {
      super(props);
      this.logOut = this.logOut.bind(this);
  
      this.state = {
        showModeratorBoard: false,
        showAdminBoard: false,
        currentUser: undefined,
      };
    }
  
    componentDidMount() {
      const user = AuthService.getCurrentUser();
  
      if (user) {
        this.setState({
          currentUser: user,
          showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
          showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        });
      }
    }
  
    logOut() {
      AuthService.logout();
    }
  
    render() {
    //   const { currentUser, showModeratorBoard, showAdminBoard } = this.state;
  
  
      return (
          <div className="landing-wrapper" style={{ backgroundImage: `url("${background}")` }}>
        <ThemeProvider theme={theme}>
            <Header />
  
          <div>
            <Switch>
              <Route exact path={["","/", "/login"]} component={Login} />
              <Route exact path="/register" component={Register} />
              <Route path="/user" component={BoardUser} />
              <Route path="/mod" component={BoardModerator} />
              <Route path="/admin" component={BoardAdmin} />
            </Switch>
          </div>
        </ThemeProvider>
        </div>
      );
    }
  }
  

export default App;
