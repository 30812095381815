import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/styles';

import AuthService from "../../services/auth.service";

import logo from '../../assets/logo2.png';

function ElevationScroll(props) {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0
    });
}

const useStyles = makeStyles(theme => ({
    toolbarMargin: {
        ...theme.mixins.toolbar,
        marginBottom: "1em"
    },
    logo: {
        height: "5em"
    }
}))

export default function Header(props) {
    const classes = useStyles()

    let currentUser = AuthService.getCurrentUser()

    return (
        <>
            <ElevationScroll>
                <AppBar position="fixed">
                    <Toolbar>
                        <img className={classes.logo} alt="company logo" src={logo} />

                        {/* {currentUser ? (
                            <div className="navbar-nav ml-auto">
                                <li className="nav-item">
                                <Button color="inherit" href="/login" onClick={this.logOut}>Logg ut</Button>
                                </li>
                            </div>
                            ) : (
                            <div className="navbar-nav ml-auto">
                                <li className="nav-item">
                                <Button color="inherit" href="/login">Logg inn</Button>
                                </li>
                
                                <li className="nav-item">
                                <Button color="inherit" href="/register">Registrer</Button>
                                </li>
                            </div>
                        )} */}

                        <Grid container direction="row" justify="flex-end" alignItems="center">
                        {currentUser ? (
                            <Button href="/login" onClick={() => { AuthService.logout() }}>Logg ut</Button>
                        ):(
                            <>
                            <Button href="/register">Registrer</Button>
                            <Button href="/login">Logg inn</Button>
                            </>
                        )}
                        </Grid>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <div className={classes.toolbarMargin} />
        </>
    );
}
