import axios from 'axios';
import authHeader from './auth-header';

const  API_URL = '/api/data/';

class DataService {

    getUnits(id) {
        return axios.get(API_URL + 'units?id=' + id, { headers: authHeader() });
    }

    MQTTPub(IMEI,pubMsg) {
        return axios.get(API_URL + 'MQTTPub?IMEI=' + IMEI + '&pubMsg=' + pubMsg, { headers: authHeader() });
    }

}

export default new DataService();