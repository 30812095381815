import React, { useState, useRef } from "react";
import ReactMapGL, { Marker, FlyToInterpolator } from "react-map-gl";
import useSupercluster from "use-supercluster";

const MapboxGLUnitsMap = ( {myContent} ) => {
    const [viewport, setViewport] = useState({
        latitude: 65,
        longitude: 10,
        width: "100%",
        height: 640, //"calc(100% - 50px)",
        zoom: 3
    });
    const mapRef = useRef();

    console.log("myContents: " + myContent.length)

    let points = []
    myContent.forEach(strdata => {
        let unit = JSON.parse(strdata)
        let point = {
            type: "Feature",
            properties: { cluster: false, id: unit.id, name: unit.Navn, Bruksvann: unit.Bruksvann, Septikk: unit.Septikk, status: (unit.Status ? unit.Status.substring(1, 2).toUpperCase() : '-') },
            geometry: unit.geometry
        }
        points.push(point)
    });

    console.log("Points: " + JSON.stringify(points))

    const bounds = mapRef.current
        ? mapRef.current
            .getMap()
            .getBounds()
            .toArray()
            .flat()
        : null;

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 75, maxZoom: 16 }
    });

    function FindMergeColor(data) {
        let S = "/vcGreen.svg";
        Promise.all(data.map(Item => {
            console.log(JSON.stringify(Item.properties));
            if( Item.properties.status === 'R' ) {
                S = "/vcRed.svg"
            } else if ( (S === "/vcGreen.svg") && (Item.properties.status === 'Y') ) {
                S = "/vcYellow.svg"
            }
            return S;
        })).then((res) => {
            //console.log(res)
        });
        return S;
    }

    return (
        <ReactMapGL
            {...viewport}
            maxZoom={16}
            mapboxApiAccessToken="pk.eyJ1Ijoib2xlcnUiLCJhIjoiY2tobmcwb2pyMHA0cDJwcXFrZnJwNDZnaSJ9.bRAY-DErXfYbb_6yLxy9ng"
            mapStyle='mapbox://styles/mapbox/satellite-v9'  //streets-v11'
            onViewportChange={newViewport => {
                setViewport({ ...newViewport });
            }}
            ref={mapRef}
        >
            {clusters.map(cluster => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const {
                    cluster: isCluster,
                    point_count: pointCount
                } = cluster.properties;

                if (isCluster) {
                    return (
                        <Marker
                            key={`cluster-${cluster.id}`}
                            latitude={latitude}
                            longitude={longitude}
                            offsetLeft={-45}
                            offsetTop={-45}
                        >
                            <button
                                className="crime-marker"
                                onClick={() => {
                                    const expansionZoom = Math.min(
                                        supercluster.getClusterExpansionZoom(cluster.id),
                                        20
                                    );

                                    setViewport({
                                        ...viewport,
                                        latitude,
                                        longitude,
                                        zoom: expansionZoom,
                                        transitionInterpolator: new FlyToInterpolator({
                                            speed: 2
                                        }),
                                        transitionDuration: "auto"
                                    });
                                }}
                            >
                                <img
                                    src={FindMergeColor(supercluster.getLeaves(cluster.id))}
                                    style={{
                                        width: 48,
                                        height: 48
                                    }}
                                    alt="crime doesn't pay"
                                />
                            </button>
                            <div className="crime-text">
                                {pointCount}
                            </div>
                        </Marker>
                    );
                } else {
                    return (
                        <Marker
                            key={`${cluster.properties.id}`}
                            latitude={latitude}
                            longitude={longitude}
                            offsetLeft={-45}
                            offsetTop={-45}
                        >
                            <button className="crime-marker"
                            onClick={() => {
                                    setViewport({
                                        ...viewport,
                                        latitude,
                                        longitude,
                                        zoom: 16,
                                        transitionInterpolator: new FlyToInterpolator({
                                            speed: 2
                                        }),
                                        transitionDuration: "auto"
                                    });
                                }}
                                >
                                <img
                                    src={(cluster.properties.status === 'R' ? "/vcRed.svg" : (cluster.properties.status === 'Y' ? "/vcYellow.svg" : "/vcGreen.svg"))}
                                    style={{
                                        width: 64,
                                        height: 64
                                    }}
                                    alt="crime doesn't pay"
                                />
                            </button>
                            <div className="crime-text-1">{cluster.properties.name}</div>
                            <div className="crime-text0">BV: {cluster.properties.Bruksvann}%</div>
                            <div className="crime-text1">{(cluster.properties.Septikk===undefined?"":"SE: " + cluster.properties.Septikk + "%")}</div>
                            
                        </Marker>
                    );
                }
            })}
        </ReactMapGL>
    );

};

export default MapboxGLUnitsMap